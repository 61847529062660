<template>
    <side-panel class="promotion-details">
        <div class="position-sticky title d-flex flex-nowrap pl-1 pr-2 pt-3 pb-2">
            <div class="mr-2">
                <router-link class="btn btn-link border-0 rounded-0"
                             to="/promotions">
                    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                </router-link>
            </div>
            <div>
                <p class="mb-1">
                    <span class="badge"
                          v-bind:style="{ 'background-color': categoryColor, 'color': categoryTextColor, }">
                        {{ categoryName }}
                    </span>
                </p>
                <h1 class="h3 font-weight-bolder mb-1">
                    {{ heading }}
                </h1>
                <h2 class="h5"
                    v-if="subheading">
                    {{ subheading }}
                </h2>
            </div>
        </div>
        <div class="cover bg-dark shadow-sm">
            <img class="h-100 w-100"
                 v-if="image"
                 v-bind:alt="heading"
                 v-bind:src="image">
        </div>
        <div class="flex-grow-1 overflow-auto p-3">
            <div class="description mb-3">
                <p>
                    {{ description }}
                </p>
            </div>
            <div class="attributes">
                <ul class="list-group rounded-xl shadow-sm"
                    v-if="attributes">
                    <li class="list-group-item d-flex justify-content-between">
                           <span class="list-name text-nowrap pr-2">
                            <strong>🌏 地區</strong>
                        </span>
                        <span class="list-value">
                            {{ regionName }} ({{ regionCode }})
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between"
                        v-for="({ name, value }, index) in attributes"
                        v-bind:key="index">
                        <span class="list-name text-nowrap pr-2">
                            <strong>{{ name }}</strong>
                        </span>
                        <span class="list-value">
                            {{ value }}
                        </span>
                    </li>
                    <a class="list-group-item list-group-item-action d-flex justify-content-between"
                       target="_blank"
                       rel="nofollow noopener noreferrer"
                       v-if="webLink"
                       v-bind:href="webLink">
                        <span class="list-name text-nowrap pr-2">
                            🌐 網站
                        </span>
                        <span>
                            <font-awesome-icon v-bind:icon="['far', 'chevron-right']"></font-awesome-icon>
                        </span>
                    </a>
                </ul>
            </div>
        </div>
        <div class="actions position-sticky px-3 py-2">
            <div class="d-flex mb-2">
                <button class="btn btn-block btn-warning rounded-pill shadow-sm mr-1 my-0"
                        v-bind:class="{ 'btn-loading': isLoading, }"
                        v-on:click="isModalEditPromotionOpen = true">
                    ✏️ Update
                </button>
                <button class="btn btn-block btn-danger rounded-pill shadow-sm ml-1 my-0"
                        v-bind:class="{ 'btn-loading': isLoading, }"
                        v-on:click="isModalDeleteConfirmOpen = true">
                    🗑️ Delete
                </button>
            </div>
            <button class="btn btn-block btn-info rounded-pill shadow-sm mb-2"
                    v-bind:class="{ 'btn-loading': isLoading, }"
                    v-on:click="isModalChatOpen = true">
                📨 Send to Chat
            </button>
        </div>
        <modal-chats is-multiple
                     v-bind:selected.sync="chatsSelected"
                     v-model="isModalChatOpen"
                     v-on:selected="sendPromotions">
        </modal-chats>
        <modal-promotion v-bind:promotion="promotion"
                         v-model="isModalEditPromotionOpen">
        </modal-promotion>
        <modal-delete-confirm field="heading"
                              v-bind:target="promotion"
                              v-bind:callback="deletePromotion"
                              v-model="isModalDeleteConfirmOpen">
        </modal-delete-confirm>
    </side-panel>
</template>

<script>
import { apiUrl, apiVersion, } from "@/config.js";
import tinycolor from "tinycolor2";
import { sendMessage, } from "@/workers/whatsapp.worker.js";
export default {
    name: "PromotionDetails",
    components: {
        SidePanel: () => import("../SidePanel"),
        ModalPromotion: () => import("./ModalPromotion"),
        ModalDeleteConfirm: () => import("../modals/ModalDeleteConfirm"),
        ModalChats: () => import("@/components/ModalChats.vue"),
    },
    props: {
        promotionId: {
            type: String,
            required: true,
        },
    },
    data () {
        return {
            isLoading: false,

            isModalChatOpen: false,
            isModalEditPromotionOpen: false,
            isModalDeleteConfirmOpen: false,

            chatsSelected: [],
        };
    },
    computed: {
        promotion () {
            return this.$store.getters["promotion/itemById"](this.promotionId);
        },
        regionCode () {
            return this.promotion?.region ?? "";
        },
        region () {
            return this.$store.getters["region/itemByCode"](this.regionCode);
        },
        regionName () {
            return this.region?.name ?? "";
        },
        heading () {
            return this.promotion?.heading ?? "";
        },
        subheading () {
            return this.promotion?.subheading ?? "";
        },
        category () {
            return this.promotion?.category ?? null;
        },
        categoryName () {
            return this.category?.name ?? "";
        },
        categoryColor () {
            return this.category?.color ?? "";
        },
        categoryTextColor () {
            const color = tinycolor(this.categoryColor);
            return color.isLight() ? "#000000" : "#FFFFFF";
        },
        image () {
            return this.promotion?.images[0] ?
                `${ apiUrl }/${ apiVersion }/promotion/image/${ this.promotion.images[0] }` :
                ``;
        },
        description () {
            return this.promotion?.description ?? "";
        },
        attributes () {
            return this.promotion?.attributes ?? null;
        },
        webLink () {
            return this.promotion?.webLink ?? "";
        },
    },
    methods: {
        async deletePromotion () {
            try {
                this.isLoading = true;
                await this.$store.dispatch("promotion/delete", this.promotionId);
                this.$router.push("/promotions");
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
        async sendPromotions () {
            const attributes = this.attributes.reduce((acc, { name, value }) => acc += `*${ name }*：${ value }\n`, "");
            const message = `*${ this.heading }*\n${ this.subheading }\n\n${ this.description }\n\n${ attributes }${ this.webLink ? `*網站*：${ this.webLink }\n` : "" }`;
            try {
                this.isLoading = true;
                await Promise.all(this.chatsSelected.map(({ vendorId }) => sendMessage(vendorId, message)));
                // this.chatsSelected = [];
            } catch (e) {
                console.error(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;
}

.actions {
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 1;
}

.cover {
    height: 250px;

    & > img {
        object-fit: cover;
    }
}

.description {
    & > p {
        white-space: pre-line;
    }
}

.attributes {
    .list-name {
        flex: 1;
    }

    .list-value {
        flex: 2;
    }
}
</style>
